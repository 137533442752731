import * as React from "react";
import { json, redirect } from "@remix-run/node";
import { Form, Link, useActionData, useSearchParams } from "@remix-run/react";
import { verifyLogin } from "~/models/user.server";
import { createUserSession, getSessionUserId } from "~/session.server";
import { validateEmail, validatePassword } from "~/utils/validation";
import { safeRedirect } from "~/utils/helpers";

import googleIcon from "~/assets/images/google-icon.png";

// Loader
export async function loader({ request }) {
  const userId = await getSessionUserId(request);
  if (userId) return redirect("/");
  return json({});
}

// Action
export async function action({ request }) {
  // Get form data
  const formData = await request.formData();
  const formEmail = formData.get("email");
  const formPassword = formData.get("password");

  const redirectTo = safeRedirect(formData.get("redirectTo"), "/");

  const remember = formData.get("remember");

  // Hold errors
  let errors = {};

  // Validate email
  const email = validateEmail(formEmail);
  if (email.error) errors = { ...errors, email: email.error };

  // Validate password
  const password = validatePassword(formPassword);
  if (password.error) errors = { ...errors, password: password.error };

  // Return any validation errors
  if (Object.keys(errors).length !== 0) {
    return json({ errors }, { status: 400 });
  }

  // Check if user with password exists
  const user = await verifyLogin(email, password);
  if (!user) {
    return json({ errors: { ...errors, email: "Invalid email or password" } }, { status: 400 });
  }

  // Create new session
  return createUserSession({
    request,
    userId: user.id,
    remember: true, // remember === "on" ? true : false,
    redirectTo,
  });
}

// Page meta
export const meta = () => {
  return [
    {
      title: "Log in to Mindsera",
    },
    {
      name: "viewport",
      content: "width=device-width, initial-scale=1.0",
    },
    {
      name: "theme-color",
      content: "#fff",
      media: "(prefers-color-scheme: light)",
    },
    {
      name: "theme-color",
      content: "#1f2937",
      media: "(prefers-color-scheme: dark)",
    },
    { name: "apple-mobile-web-app-capable", content: "yes" },
    { name: "robots", content: "noindex" },
    // og: Open Graph
    { property: "og:title", content: "Login to Mindsera" },
    { property: "og:type", content: "website" },
    { property: "og:url", content: "https://beta.mindsera.com" },
    {
      property: "og:image",
      content: "https://beta.mindsera.com/images/mindsera-og.jpg",
    },
    { property: "og:description", content: "" },
    // twitter
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:site", content: "@mindseraHQ" },
    { name: "twitter:creator", content: "@mindseraHQ" },
    { name: "twitter:title", content: "Login to Mindsera" },
    { name: "twitter:description", content: "" },
    {
      name: "twitter:image",
      content: "https://beta.mindsera.com/images/mindsera-og.jpg",
    },
  ];
};

// Login page
export default function LoginPage() {
  const actionData = useActionData();
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get("redirectTo") || "/";

  const emailRef = React.useRef(null);
  const passwordRef = React.useRef(null);

  // In case of error, focus on right input
  React.useEffect(() => {
    if (actionData?.errors?.email) {
      emailRef.current?.focus();
    } else if (actionData?.errors?.password) {
      passwordRef.current?.focus();
    }
  }, [actionData]);

  return (
    <>
      {/* Card */}
      <div className="mx-auto w-full max-w-md rounded-3xl bg-white px-8 py-10 shadow-large dark:bg-gray-800">
        {/* Title */}
        <h1 className="mb-2 text-center text-3xl font-light text-black dark:text-white">Log In</h1>

        {/* Subtitle */}
        <div className="mb-4 text-center text-lg font-light text-gray-500 dark:text-gray-400">
          Don't have an account yet?{" "}
          <Link className="underline" to="/join?trial=1">
            Sign up
          </Link>
        </div>

        {/* Form */}
        <Form method="post" noValidate>
          {/* Input group */}
          <div className="space-y-4">
            {/* Email */}
            <div>
              <div className="mt-1">
                <input
                  ref={emailRef}
                  id="email"
                  required
                  autoFocus={true}
                  name="email"
                  type="email"
                  placeholder="Email"
                  autoComplete="email"
                  aria-invalid={actionData?.errors?.email ? true : undefined}
                  aria-describedby="email-error"
                  className="w-full rounded-lg bg-gray-100 px-4 py-3 text-lg text-black outline-none dark:bg-gray-900 dark:text-white"
                />

                {actionData?.errors?.email && (
                  <div className="pt-1 text-red-500 dark:text-red-400" id="email-error">
                    {actionData.errors.email}
                  </div>
                )}
              </div>
            </div>

            {/* Password */}
            <div>
              <div className="mt-1">
                <input
                  id="password"
                  ref={passwordRef}
                  name="password"
                  type="password"
                  placeholder="Password"
                  autoComplete="current-password"
                  aria-invalid={actionData?.errors?.password ? true : undefined}
                  aria-describedby="password-error"
                  className="w-full rounded-lg bg-gray-100 px-4 py-3 text-lg text-black outline-none dark:bg-gray-900 dark:text-white"
                />

                {actionData?.errors?.password && (
                  <div className="pt-1 text-red-500 dark:text-red-400" id="password-error">
                    {actionData.errors.password}
                  </div>
                )}
              </div>
            </div>

            <input type="hidden" name="redirectTo" value={redirectTo} />
          </div>

          {/* Submit */}
          <button
            type="submit"
            className="mt-6 w-full rounded-lg bg-gray-900 px-5 py-4 text-xl font-light text-white dark:bg-white dark:text-black hover:dark:bg-white"
          >
            Continue
          </button>
        </Form>

        <div className="my-6 text-center text-lg font-light text-gray-500 dark:text-gray-400">
          or
        </div>

        <Form method="post" action={`/auth/google`}>
          <button
            type="submit"
            className="flex h-[60px] w-full items-center justify-center rounded-lg border-2 border-black bg-white px-5 py-4 text-xl font-light text-black"
          >
            <img src={googleIcon} width={21} height={22} className="mr-2" alt="Google auth" />
            Log in with Google
          </button>
        </Form>
        {/* Forgot password */}
        <div className="mt-6 px-2 text-center text-lg font-light text-gray-500 dark:text-gray-400">
          <Link className="underline" to="/forgot-password">
            Forgot your password?
          </Link>
        </div>
      </div>
    </>
  );
}
